<template>
  <v-container fluid>
    <v-layout align-center justify-center row fill-height>
      <v-flex xs12 sm8 md5>
        <v-card>
          <v-toolbar dark color="primary">
            <v-spacer></v-spacer>
            <v-toolbar-title class="justify-center">Alterar senha</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                present
                v-model="user.senhaAtual"
                :rules="senhaRules"
                prepend-icon="lock"
                name="senhaAtual"
                label="Senha atual"
                type="password"
                required
              ></v-text-field>
              <v-text-field
                present
                v-model="user.senha"
                :rules="senhaRules"
                prepend-icon="lock"
                name="senha"
                label="Nova senha"
                type="password"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.novaSenha"
                :rules="senhaRules"
                prepend-icon="lock"
                name="novaSenha"
                label="Confirme nova senha"
                type="password"
                required
              ></v-text-field>
              <v-card-actions>
                <v-btn @click="confirmar(user)" block dark round color="green">Confirmar</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const usuarioModule = () => import('./../../../services/usuario')

export default {
  name: 'Usuario-Senha',
  data () {
    return {
      user: {
        senhaAtual: null,
        senha: null,
        novaSenha: null
      },
      valid: true,
      senhaRules: [
        v => !!v || 'Campo obrigatório.'
      ],
    }
  },
  methods: {
    async confirmar (user) {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        if (user.senha !== user.novaSenha) {
          this.$swal('Senhas não conferem.', '', 'error')
          return
        }
        let usuarioService = await usuarioModule()
        let resp = await usuarioService.alterarSenha(this.$axios, { ...user })
        if (resp.status === 200) {
          if (resp.data.success) {
            this.$toast.success(resp.data.msg)
            this.$router.push('/dashboard')
          } else {
            this.$swal(resp.data.msg, '', 'error')
          }
        }

      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>